import React from 'react';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { SellerBuysideSearchResult } from '../../../../types/bid-as-dealer/SellerBuysideSearchResult';
import { moneyUtils } from '../../../../utils';
import { routes, SORTING_TYPE } from '../../../../constants';
import { ParsedBwicLabel } from '../../../common/ParsedBwicLabel';
import { Link } from '../../../controls/Link';

interface BuyColumns extends SellerBuysideSearchResult {
    isinCusip: string;
    ticker: string;
}

const buyColumnsDefinitions: IColumnDefinition<BuyColumns>[] = [{
    columnKey: 'parsed',
    renderColumnHeaderContent: () => '',
    renderColumnContent: entity => <ParsedBwicLabel isParsed={entity.bwic.isParsed} />,
    className: 'data-list-parsed hidden'
}, {
    columnKey: 'isin-cusip',
    renderColumnHeaderContent: () => 'Identifier',
    renderColumnContent: entity => entity.position.isinCusip,
    className: 'data-list-isin hidden',
    sortingField: 'isinCusip',
    sortingType: SORTING_TYPE.string
}, {
    columnKey: 'ticker',
    renderColumnHeaderContent: () => 'Ticker',
    renderColumnContent: entity => entity.position.ticker,
    className: 'data-list-ticker',
    sortingField: 'ticker',
    sortingType: SORTING_TYPE.string
}, {
    columnKey: 'rating',
    renderColumnHeaderContent: () => 'Rtg',
    renderColumnContent: entity => entity.position.rating,
    className: 'data-list-rating'
}, {
    columnKey: 'size',
    renderColumnHeaderContent: () => 'Size, MM',
    renderColumnContent: entity => moneyUtils.amountToMM(entity.position.size),
    className: 'data-list-size padding-r-0 text-right'
}, {
    columnKey: 'view',
    renderColumnHeaderContent: () => '',
    renderColumnContent: () => <Link to={routes.sellerBuy}>View</Link>,
    headerClassName: '',
    bodyClassName: 'flex-item-right',
}];

export const buyColumns = buyColumnsDefinitions.map(c => new ColumnBuilder(c));
